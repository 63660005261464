<template>
  <div class="w-full">
    <TitleWrapper
      class="mb-5"
      :filter-option="false"
      title="Profile"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div :key="listenStorage" class="p-7 bg-white rounded-lg">
      <!-- Personal Information -->
      <div>
        <div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Name`) }}:</div>
            <div class="text-menu capitalize">
              {{ currentTeacher.first_name }} {{ currentTeacher.last_name }}
            </div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Gender`) }}:</div>
            <div class="text-menu">{{ currentTeacher.gender || $t(`dashboard.SP_N_A`) }}</div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Blood Group`) }}:</div>
            <div class="text-menu">
              {{
                currentTeacher.profile ? currentTeacher.profile.blood_group : $t(`dashboard.SP_N_A`)
              }}
            </div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Date of Birth`) }}:</div>
            <div class="text-menu">
              {{
                currentTeacher.profile
                  ? currentTeacher.profile.date_of_birth
                  : $t(`dashboard.SP_N_A`)
              }}
            </div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-12">
            <div class="w-40">{{ $t(`dashboard.Nationality`) }}:</div>
            <div class="text-menu">
              {{
                currentTeacher.profile ? currentTeacher.profile.nationality : $t(`dashboard.SP_N_A`)
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- Contact Information -->
      <div>
        <div
          v-i18n="title"
          class="pb-2.5 font-medium font-roboto text-xl text-label-text border-b border-primary-grey"
        >
          CON_INFO
        </div>
        <div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Phone`) }}:</div>
            <div class="text-menu">{{ currentTeacher.phone || $t(`dashboard.SP_N_A`) }}</div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.SP_H2_T2`) }}:</div>
            <div class="text-menu">{{ currentTeacher.email || $t(`dashboard.SP_N_A`) }}</div>
          </div>
          <div class="mt-5 flex font-roboto font-base mb-4">
            <div class="w-40">{{ $t(`dashboard.Address`) }}:</div>
            <div class="text-menu">
              {{ currentTeacher.profile ? currentTeacher.profile.address : $t(`dashboard.SP_N_A`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TeacherModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentTeacher"
      @toggle="teacherModalToggle"
    ></TeacherModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TitleWrapper from '@components/TitleWrapper.vue'
import TeacherModal from '@/src/router/views/admin/modals/TeacherModal.vue'

export default {
  components: {
    TitleWrapper,
    TeacherModal,
  },
  data() {
    return {
      toggleModal: false,
      get currentTeacher() {
        return JSON.parse(localStorage.getItem('currentActiveTeacher'))
      },
      set currentTeacher(value) {
        JSON.parse(localStorage.getItem('currentActiveTeacher'))
      },
      forceReRender: 1,
      dashboard: 'dashboard',
      title: 'title',
    }
  },
  computed: {
    ...mapState({
      listenStorage: (state) => state.teacher.listenStorage,
      showModal: (state) => state.layout.showModal,
    }),
  },

  watch: {
    showModal: {
      handler() {
        this.toggleModal = true
      },
    },
    listenStorage: {
      handler(value) {
        if (value) this.updateTeacher()
      },
    },
  },
  created() {
    this.currentTeacher = JSON.parse(localStorage.getItem('currentActiveTeacher'))
    this.setRightbarData({})
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    toggleTeacher() {
      this.toggleModal = false
      const rightBarContent = {
        header: {
          title: 'Profile Details',
          buttons: [
            {
              title: 'Edit Profile',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        profileInfo: [
          {
            imageUrl: this.currentTeacher?.image || null,
            metaInfo: [
              {
                lastClass: '23/08/2020',
                attendance: '24',
                leave: '4',
                absent: '5',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    updateTeacher() {
      this.currentTeacher = JSON.parse(localStorage.getItem('currentActiveTeacher'))
      this.setRightbarData()
      this.$store.commit('teacher/SET_LISTEN_STORAGE', false)
      localStorage.setItem('currentActiveTeacher', JSON.stringify(this.currentTeacher))
      this.forceReRender++
    },

    teacherModalToggle(payload) {
      this.setShowModal(false)
      this.updateTeacher()
      if (!this.showModal) this.currentTeacher = null
    },

    setRightbarData() {
      const rightBarContent = {
        header: {
          title: 'Profile Details',
          buttons: [
            {
              title: 'Edit Profile',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        profileInfo: [
          {
            imageUrl: this.currentTeacher?.image || null,
            metaInfo: [
              {
                lastClass: '23/08/2020',
                attendance: '24',
                leave: '4',
                absent: '5',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>
